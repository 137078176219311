export function formatDateToISOWithTimezoneOffset(date, timeZoneOffset) {
	// Get date parts
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	const milliseconds = String(date.getMilliseconds()).slice(0, 2).padStart(2, '0');

	// Convert timezone offset to hours and minutes;
	const offsetSign = timeZoneOffset >= 0 ? '+' : '-';
	const offsetHours = String(Math.floor(Math.abs(timeZoneOffset))).padStart(2, '0');
	const offsetMinutes = '00';

	// Format the date string
	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}
